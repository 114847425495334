import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const RefinancingPageTemplate = ({
  content,
  contentComponent,
  title,
  image,
  foot,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <section>
      {helmet || ""}
      <div>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url(${
              !!image.childImageSharp ? image.childImageSharp.fluid.src : image
            })`,
          }}
        >
          <h2 className="has-text-weight-bold has-text-white is-size-1">
            {title}
          </h2>
        </div>
      </div>
      <div className="section content">
        <div
          className="container px-6"
          style={{
            lineHeight: 3,
          }}
        >
          <PostContent content={content} />
        </div>
      </div>
      <div className="section">
        <div className="container">
          <h2 className="title is-2 has-text-centered">{foot.title}</h2>
          <hr />
        </div>
        <div className="container">
          <div className="columns is-multiline">
            {Array.isArray(foot.blurbs)
              ? foot.blurbs.map((blurb, idx) => (
                  <div key={`${idx}-foot-blurb`} className="column is-half">
                    <h4 className="title is-4">{blurb.title}</h4>
                    <p>{blurb.text}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </section>
  );
};

RefinancingPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  foot: PropTypes.shape({
    title: PropTypes.string,
    blurbs: PropTypes.array,
  }),
  helmet: PropTypes.object,
};

const RefinancingPage = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <Layout>
      <RefinancingPageTemplate
        content={markdownRemark.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="Refinancing | Get Started">
            <title>{`${markdownRemark.frontmatter.title}`}</title>
            <meta name="description" content={`Refinancing`} />
          </Helmet>
        }
        title={markdownRemark.frontmatter.title}
        image={markdownRemark.frontmatter.image}
        foot={markdownRemark.frontmatter.foot}
      />
    </Layout>
  );
};

RefinancingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default RefinancingPage;

export const RefinancingPageQuery = graphql`
  query RefinancingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        foot {
          title
          blurbs {
            title
            text
          }
        }
      }
    }
  }
`;
